import {formatChecks} from '@backstage/utils/json-schema-format-helpers-all';
import Ajv, {Options} from 'ajv/dist/2019';
import {
  FLOW_IGNORE,
  FLOW_NODE_VERSION,
  PRIVATE_FIELD_DATA,
} from './helper-constants';

/**
 * Create an AJV validator configured with options and formats used by typebox.
 */
export function createSchemaValidator(options?: Options): Ajv {
  let ajv = new Ajv(options ?? {});
  for (const [id, fn] of Object.entries(formatChecks)) {
    ajv = ajv.addFormat(id, fn);
  }
  return ajv
    .addKeyword(FLOW_IGNORE)
    .addKeyword(FLOW_NODE_VERSION)
    .addKeyword(PRIVATE_FIELD_DATA)
    .addKeyword('kind')
    .addKeyword('modifier');
}
