import {Box, Button, Flex, useDisclosure, Text} from '@chakra-ui/react';
import {ChevronUpIcon, ChevronDownIcon, ViewIcon} from '@chakra-ui/icons';
import {VFC, ReactNode} from 'react';
import {motion} from 'framer-motion';

const MotionBox = motion(Box);

interface ModuleCollapseProps {
  children: ReactNode;
  moduleName: string;
}

export const ModuleCollapse: VFC<ModuleCollapseProps> = ({
  children,
  moduleName,
}) => {
  const {isOpen, onToggle} = useDisclosure({
    defaultIsOpen: true,
  });

  return (
    <>
      <Flex
        bgColor="#191919"
        color="#f5f5f5"
        paddingLeft={2.5}
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <ViewIcon w={5} h={5} />{' '}
          <Text marginLeft={2} fontWeight="bold">
            {moduleName}
          </Text>
        </Flex>
        <Button
          p={15}
          border="none"
          bgColor="transparent"
          color="#f5f5f5"
          borderRadius={0}
          _hover={{
            backgroundColor: '#303030',
          }}
          onClick={onToggle}
        >
          {isOpen ? (
            <>
              Collapse <ChevronUpIcon w={26} h={26} />
            </>
          ) : (
            <>
              Expand <ChevronDownIcon w={26} h={26} />
            </>
          )}
        </Button>
      </Flex>

      <MotionBox
        initial="open"
        animate={isOpen ? 'open' : 'collapsed'}
        variants={{
          open: {opacity: 1, height: 'auto'},
          collapsed: {opacity: 0, height: 0},
        }}
        style={{
          overflow: 'hidden',
          height: 'auto',
        }}
      >
        {children}
      </MotionBox>
    </>
  );
};
