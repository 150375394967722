import {useEffect, useState} from 'react';

export const FALLBACK_LOCALE = 'en-US';
/**
 * Hook for determining a component's locale.
 * Checks {@link contextLocale} before referring to browser's Navigator object.
 * A fallback locale is set under {@link FALLBACK_LOCALE}
 * @param contextLocale A string from context that will set a component's locale
 * @returns A string describing locale.
 */
export const useLocale = (contextLocale?: string): string => {
  const [locale, setLocale] = useState(contextLocale);
  useEffect(() => {
    setLocale((current) => {
      const navigatorLanguage = navigator
        ? navigator.language ?? navigator.languages?.[0]
        : undefined;
      if (
        typeof current === 'undefined' &&
        typeof contextLocale === 'undefined'
      ) {
        // if no value fallback to navigator language
        return navigatorLanguage;
      } else if (
        typeof contextLocale === 'string' &&
        contextLocale !== current
      ) {
        // if `contextLocale` was provided use it
        return contextLocale;
      } else {
        // otherwise return the current value
        return current;
      }
    });
  }, [contextLocale]);
  return locale ?? FALLBACK_LOCALE;
};
