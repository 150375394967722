import type {Static} from '@sinclair/typebox';
import {StringEnum} from './typebox-helpers';

export const orientationAttr = StringEnum(['horizontal', 'vertical'] as const, {
  title: 'Orientation',
  default: 'vertical',
});

export type Orientation = Static<typeof orientationAttr>;

const orientationPattern = /^(vertical|horizontal)$/;

export const defaultOrientation: Orientation = 'vertical';

export const isOrientation = (o: unknown): o is Orientation => {
  return typeof o === 'string' ? orientationPattern.test(o) : false;
};

export type Oriented = {
  orientation: Orientation;
};
