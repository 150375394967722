import {
  useEffect,
  useReducer,
  useRef,
  type Reducer,
  type RefObject,
} from 'react';
import {useManagedRef} from './useManagedRef';

/**
 * Creates a `<script>` element and injects it into the `<body>` of the
 * document. When the `useExternalScript` hook "unmounts" the `<script>`
 * element is *not* removed because once a script has been loaded it has access
 * to the page removing the DOM node doesn't change that. Passing a new `id`
 * will inject a _new_ script element. The `onload` function is invoked when
 * the script has finished loading as long as the `useExternalScript` hook is
 * still mounted. The `onunload` function is invoked when the
 * `useExternalScript` is unmounted as long as the `<script>` tag had already
 * completed loading.
 */
export function useExternalScript(
  options: UseExternalScriptOptions
): RefObject<Readonly<HTMLScriptElement> | undefined> {
  const {isParsed = () => false} = options;
  const element = useRef<HTMLScriptElement>();
  const onloadRef = useManagedRef(options.onload);
  const onunloadRef = useManagedRef(options.onunload);
  // Initial state depends on whether the `<script>` has previously loaded
  const [state, dispatch] = useReducer(
    loadStateReducer,
    isParsed() ? 'unloaded' : 'init'
  );

  // Execute the `onload` and `onunload` functions as needed based on state
  // changes
  useEffect(() => {
    const onload = onloadRef.current;
    const onunload = onunloadRef.current;
    if (state === 'loaded') {
      onload?.();
    } else if (state === 'unloaded') {
      onunload?.();
    }
  }, [onloadRef, onunloadRef, state]);

  // Create the `<script>` element and inject it if one doesn't already exist
  // with `options.id`
  useEffect(() => {
    const existing = (): HTMLElement | null =>
      document.getElementById(options.id);
    const el = existing();
    if (el instanceof HTMLScriptElement) {
      if (typeof element.current === 'undefined') {
        element.current = el;
      }
      dispatch('synth-load');
      return;
    }
    const script = document.createElement('script');
    script.id = options.id;
    script.src = options.src;
    script.async = true;
    script.onload = () => dispatch('load');
    if (typeof options.integrity === 'string') {
      script.integrity = options.integrity;
    }
    if (typeof options.crossOrigin === 'string') {
      script.crossOrigin = options.crossOrigin;
    }
    if (existing() === null) {
      element.current = script;
      document.body.appendChild(script);
    }
    return () => {
      dispatch('unload');
    };
  }, [options.crossOrigin, options.id, options.integrity, options.src]);
  return element;
}

interface UseExternalScriptOptions
  extends Pick<HTMLScriptElement, 'src' | 'id'>,
    Partial<Pick<HTMLScriptElement, 'integrity' | 'crossOrigin'>> {
  /**
   * A test predicate to determine if the `<script>` element is already parsed.
   */
  isParsed?: () => boolean;
  /**
   * Callback to execute when the `<script>` element enters a `loaded` state.
   * This function may be invoked the first time a `<script>` is loaded or
   * after the `useExternalScript` has been remounted and so must be able to
   * run successfully in both situations.
   */
  onload?: () => void;
  /** Callback to execute when the `<script>` element is removed */
  onunload?: () => void;
}

/**
 * Represents the state of the external script.
 * - *init* means the script has not yet loaded for the first time
 * - *loaded* means the script has been loaded and is considered active
 * - *unloaded* means the script has been loaded and is considered inactive
 */
type ExternalScriptState = 'init' | 'loaded' | 'unloaded';

/**
 * Actions to trigger state changes
 * - *load* means the script has been loaded into the page
 * - *unload* means `useExternalScript` has been unmounted
 * - *synth-load* means `useExternalScript` has been re-mounted with a
 *   `<script>` tag already in the page
 */
type ExternalScriptAction = 'load' | 'unload' | 'synth-load';

/** Compute the next state based on the current state and the incoming action */
const loadStateReducer: Reducer<ExternalScriptState, ExternalScriptAction> = (
  draft,
  action
) => {
  if (action === 'load' && draft === 'init') {
    return 'loaded';
  } else if (action === 'unload' && draft === 'loaded') {
    return 'unloaded';
  } else if (action === 'synth-load' && draft === 'unloaded') {
    return 'loaded';
  } else {
    return draft;
  }
};
