import {useEffect, useRef, type MutableRefObject} from 'react';

/**
 * A variation on the `useRef` hook that updates the `current` value of the
 * `RefObject` with the value passed in. This is useful for creating stable
 * references to callbacks and other values inside `useCallback` or `useMemo`
 * where the consumer of the value should always use the latest value but the
 * memoized value does not need to be re-created when `value` changes. Use with
 * caution.
 *
 * _NOTE_: Importing this function as `useRef` will cause the `eslint` plugin
 * with the `react-hooks/exhaustive-deps` rule to treat the result of this
 * function as stable because the `react-hooks/exhaustive-deps` rule
 * implementation looks for an identifier called `"useRef"`.
 * @see https://github.com/facebook/react/blob/4dda96a4071d2cc4bbcc444438cfacee991f07e2/packages/eslint-plugin-react-hooks/src/ExhaustiveDeps.js#L226
 */
export function useManagedRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
}
