import type {Instruction} from '@backstage/instructions';
import {useSubscription} from 'observable-hooks';
import {useMemo, useRef, type Reducer, type RefObject} from 'react';
import {type Observable, filter, scan} from 'rxjs';

/**
 * This is the same as `RefObject` from `react` except the `current` value can
 * not be null.
 */
export interface NonNullRefObject<T> extends RefObject<T> {
  readonly current: Exclude<RefObject<T>['current'], null>;
}

const initialState: ShowState = {global: {}, local: {}};

/**
 * Reduce over `instructions` as they come in to update `ShowState`.
 */
export function useShowState(
  instructions: Observable<Instruction>
): NonNullRefObject<ShowState> {
  const o = useMemo(
    () =>
      instructions.pipe(
        filter(isStateAction),
        scan(siteStateReducer, initialState)
      ),
    [instructions]
  );
  const showStateRef = useRef(initialState);
  useSubscription(o, {
    next: (s) => {
      showStateRef.current = s;
    },
  });
  return showStateRef;
}

/**
 * Given a current `SiteState` comprised of `global` and `local` `State` values
 * update the state based on the given `action`.
 * @private exported for tests
 */
export const siteStateReducer: Reducer<ShowState, StateAction> = (
  draft,
  action
) => {
  let nextGlobal = draft.global;
  let nextLocal = draft.local;
  switch (action.type) {
    case 'Global:variable:set':
      nextGlobal = Object.assign({}, draft.global, action.meta);
      break;
    case 'Global:variable:unset':
      nextGlobal = Object.assign({}, draft.global);
      for (const key of Object.keys(action.meta)) {
        delete nextGlobal[key];
      }
      break;
    case 'variable:set':
      nextLocal = Object.assign({}, draft.local, action.meta);
      break;
    case 'variable:unset':
      nextLocal = Object.assign({}, draft.local);
      for (const key of Object.keys(action.meta)) {
        delete nextLocal[key];
      }
      break;
  }
  return {global: nextGlobal, local: nextLocal};
};

/**
 * Check if the given `action` is an `Instruction` intended to interact with
 * the state of a site.
 */
function isStateAction(action: Instruction): action is StateAction {
  const regex = /^(Global:)?variable:(un)?set$/;
  return regex.test(action.type);
}

/** Represents the reduced state for global or local variables. */
type State = Record<string, string>;

/** Combination of global and local state */
export interface ShowState {
  /**
   * Global variable values based on the setting and unsetting of global
   * variables
   */
  global: State;
  /**
   * Local variable values based on the setting and unsetting of local variables
   */
  local: State;
}

interface GlobalVariableSet extends Instruction {
  type: 'Global:variable:set';
}

interface GlobalVariableUnset extends Instruction {
  type: 'Global:variable:unset';
}

interface LocalVariableSet extends Instruction {
  type: 'variable:set';
}

interface LocalVariableUnset extends Instruction {
  type: 'variable:unset';
}

/**
 * Type narrowed union of instructions which can manipulate global or local
 * state
 */
type StateAction =
  | GlobalVariableSet
  | GlobalVariableUnset
  | LocalVariableSet
  | LocalVariableUnset;
