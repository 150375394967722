import {useMemo} from 'react';

/**
 * Identifies the `id` of a `SiteVersion` record associated with the current
 * page. Result is memoized on first run, it is considered immutable once read
 * the first time.
 * @returns the current `id` of the to be displayed `SiteVersion`
 */
export function useSiteVersionId(): string | undefined {
  return useMemo(
    () =>
      document && document.body
        ? document.body.dataset['siteVersionId']
        : undefined,
    []
  );
}
