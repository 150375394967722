import {getFlowStorageKey, storage} from '../../../helpers';
import {
  downgradeLegacyFlowExport,
  type OverallFlowData,
} from '@backstage/flows';

export const getData = (domainName?: string): OverallFlowData => {
  if (typeof storage === 'undefined') {
    return {tabs: []};
  }

  const flowData: OverallFlowData = JSON.parse(
    storage.getItem(getFlowStorageKey(domainName)) || '{"tabs": []}'
  );

  flowData.tabs.forEach((tab) => {
    // At this time, we are keeping the ReactFlow <10.0.0 data format
    // because we have types and tooling in that format.
    tab.data = downgradeLegacyFlowExport(tab.data);
  });

  /*
   * It is more reliable to derive the key while reading instead of when the data
   * is written to `storage`. We would otherwise need to ensure that all code paths
   * that write this `storage` value properly add the `key`.
   * NOTE: `key` will not be visible in localStorage.
   */
  flowData.key = [flowData.id ?? '']
    .concat(flowData.tabs.map((x) => x.id))
    .join(';');

  return flowData;
};
