/**
 * Get a random integer clamped to a minimum and maxiumum value
 * @param min the minimum value to return
 * @param max the maximum value to return
 * @returns the random integer
 */
export const randomInt = (min: number, max: number): number => {
  if (min > max) {
    throw new RangeError('min may not be greater than max');
  }
  return Math.floor(min + Math.random() * (max - min + 1));
};

/**
 * Clamps an number to a minimum and maximum value. If the integer is above or below the clamped
 * values the function will return the clamped min/max respectively
 * @param min the minimum value
 * @param max the maximum value
 * @returns the clamped value
 */
export const clamp = (value: number, min: number, max: number): number => {
  if (min > max) {
    throw new RangeError('min may not be greater than max');
  }
  return Math.max(Math.min(value, max), min);
};

/**
 * Map number x from range [a, b] to [c, d]
 * @param x the incoming value to be converted
 * @param inMin lower bound of the value's current range
 * @param inMax upper bound of the value's current range
 * @param outMin lower bound of the value's target range
 * @param outMax upper bound of the value's target range
 * @returns the converted value based on the target range
 */
export const map = (
  x: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
): number => {
  if (inMin > inMax || outMin > outMax) {
    throw new RangeError('min may not be greater than max');
  }
  return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

/**
 * Linear interpolation
 * @param a the lower bound
 * @param b the upper bound
 * @param n the value to lerp
 * @returns the clamped value
 */
export const lerp = (a: number, b: number, n: number): number =>
  (1 - n) * a + n * b;
