import {markPrivateProperties} from '../helpers/component-admin-helpers';
import {
  ComponentAdmin,
  ContentModule,
  DynamicComponent,
  LayoutModule,
  ModuleProperties,
} from '../types';
import {Module} from './module';
import {RegistryError, RenderError} from './errors';
import {NextModule, Renderer} from './renderer';

type AnyModule = Module<string, ModuleProperties>;

/**
 * Creates a class representing a registry of `Renderer` implementations matched
 * to component ids.
 * @private exported for tests
 */
export class RegistrySingleton implements Renderer {
  private handlers: Record<'deregister' | 'register', RegistryHandler[]>;
  private renderMap: Map<string, AnyModule>;

  constructor() {
    this.handlers = {
      deregister: [],
      register: [],
    };
    this.renderMap = new Map();
  }

  /**
   * @returns `Iterable` containing the list of registered component ids.
   */
  get componentIds(): Iterable<string> {
    return this.renderMap.keys();
  }

  /**
   * Remove association between the given `renderer` and the component
   * identified in `component`.
   * @param component identifying information.
   * @param renderer expected to be associated with the `component`.
   * @returns `this` for chaining.
   * @throws `RegistryError` if `renderer` is not the same instance as the one
   * in the registry.
   */
  deregister(
    component: ComponentAdmin,
    renderer: AnyModule
  ): RegistrySingleton {
    const existing = this.renderMap.get(component.id);
    if (typeof existing !== 'undefined' && existing !== renderer) {
      throw new RegistryError(
        'Rendering module differs from registered module.'
      );
    } else {
      this.renderMap.delete(component.id);
    }
    this.handlers.deregister.forEach((h) => h(component));
    return this;
  }

  /**
   * Test if `definition` refers to a `ContentModule` based on the registered
   * `Module`.
   * @param definition to test against registered `Module`.
   * @returns `true` if `definition` should be treated as a `ContentModule`.
   */
  isContent<
    Kind extends string = string,
    Props extends ModuleProperties = ModuleProperties,
  >(
    definition: DynamicComponent<Kind, Props>
  ): definition is ContentModule<Kind, Props> {
    const existing = this.renderMap.get(definition.cid);
    if (typeof existing === 'undefined') {
      throw new RegistryError(
        `No rendering module exists for ${definition.cid}.`
      );
    }
    return existing.isContent(definition);
  }

  /**
   * Test if `definition` refers to a `LayoutModule` based on the registered
   * `Module`.
   * @param definition to test against registered `Module`.
   * @returns `true` if `definition` should be treated as a `LayoutModule`.
   */
  isLayout<
    Kind extends string = string,
    Props extends ModuleProperties = ModuleProperties,
  >(
    definition: DynamicComponent<Kind, Props>
  ): definition is LayoutModule<Kind, Props> {
    const existing = this.renderMap.get(definition.cid);
    if (typeof existing === 'undefined') {
      throw new RegistryError(
        `No rendering module exists for ${definition.cid}.`
      );
    }
    return existing.isLayout(definition);
  }

  /**
   * Remove a handler for the `deregister` event, if no `handler` is provided
   * removes all handlers.
   */
  off(type: 'deregister', handler?: RegistryHandler): void;
  /**
   * Remove a handler for the `register` event, if no `handler` is provided
   * removes all handlers.
   */
  off(type: 'register', handler?: RegistryHandler): void;
  off(type: 'deregister' | 'register', handler?: RegistryHandler): void {
    const handlers = this.handlers[type];
    if (typeof handler === 'undefined') {
      this.handlers[type] = [];
    } else if (handlers.includes(handler)) {
      this.handlers[type] = handlers.filter((h) => h !== handler);
    }
  }

  /**
   * Add a handler for the `deregister` event, triggered whenever a component
   * is removed from the registry.
   */
  on(type: 'deregister', handler: RegistryHandler): void;
  /**
   * Add a handler for the `register` event, triggered whenever a component
   * is added to the registry.
   */
  on(type: 'register', handler: RegistryHandler): void;
  on(type: 'deregister' | 'register', handler: RegistryHandler): void {
    const handlers = this.handlers[type];
    if (!handlers.includes(handler)) {
      handlers.push(handler);
    }
  }

  /**
   * Associate the given `renderer` with the component identified in
   * `component`.
   * @param component identifying information.
   * @param renderer to associate with the `component`.
   * @returns `this` for chaining.
   * @throws `RegistryError` if there is already a rendering module associated
   * with `component`.
   */
  register(component: ComponentAdmin, renderer: AnyModule): RegistrySingleton {
    if (component.privateSchema) {
      markPrivateProperties(component.privateSchema);
    }
    const existing = this.renderMap.get(component.id);
    if (typeof existing !== 'undefined') {
      console.warn(`Rendering module already exists for ${component.id}.`);
      return this;
    } else {
      this.renderMap.set(component.id, renderer);
    }
    this.handlers.register.forEach((h) => h(component));
    return this;
  }

  /**
   * Attempts to render a given `DynamicComponent` based on the registered
   * `Module` definitions.
   * @param definition of the component or module to be rendered.
   * @returns the element from the first renderer which does not indicate next.
   * @throws `RenderError` if no module can be found to render the given
   * `definition` in the registry.
   */
  tryRender(definition: DynamicComponent): JSX.Element {
    const existing = this.renderMap.get(definition.cid);
    if (typeof existing === 'undefined') {
      throw new RegistryError(
        `No rendering module exists for ${definition.cid}.`
      );
    }
    const result = existing.tryRender(definition);
    if (result !== NextModule) {
      return result;
    }
    throw new RenderError(`No rendering module for ${definition.component}.`);
  }
}

/**
 * A handler function for use with the 'register' and 'deregister' events of the
 * `Registry`.
 * @param component being added or removed
 */
export type RegistryHandler = (component: ComponentAdmin) => void;

/**
 * A shared registry of `Renderer` implementations matched to component ids.
 */
export const Registry = new RegistrySingleton();
