"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeType = exports.NodeFieldType = exports.VALUE_NOT_PRESENT = exports.DEAD_END = void 0;
exports.DEAD_END = Symbol('DEAD_END');
exports.VALUE_NOT_PRESENT = Symbol('VALUE_NOT_PRESENT');
exports.NodeFieldType = {
    string: 'string',
    number: 'number',
    boolean: 'boolean',
    multilineString: 'multilineString',
};
exports.NodeType = {
    emitter: 'emitter',
    logic: 'logic',
    about: 'about',
    listener: 'listener',
    macro: 'macro',
    value: 'value',
};
