"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downgradeLegacyFlowExport = exports.upgradeLegacyFlowExport = exports.isLegacyFlowExport = void 0;
const array_helpers_1 = require("@backstage/utils/array-helpers");
const isLegacyFlowExport = (obj) => {
    if (!obj) {
        return false;
    }
    if (typeof obj === 'object') {
        if ('elements' in obj && 'position' in obj && 'zoom' in obj) {
            return true;
        }
    }
    return false;
};
exports.isLegacyFlowExport = isLegacyFlowExport;
const upgradeLegacyFlowExport = (obj) => {
    if (typeof obj === 'object' && 'edges' in obj) {
        return obj;
    }
    const [edges, nodes] = (0, array_helpers_1.partition)(obj.elements, (el) => 'source' in el);
    return {
        // Casting is safe here, as the saved values can only be nodes or edges.
        nodes: nodes,
        edges: edges,
        viewport: {
            x: obj.position[0],
            y: obj.position[1],
            zoom: obj.zoom,
        },
    };
};
exports.upgradeLegacyFlowExport = upgradeLegacyFlowExport;
const downgradeLegacyFlowExport = (obj) => {
    if (typeof obj === 'object' && 'elements' in obj) {
        return obj;
    }
    const { nodes = [], edges = [], viewport } = obj ?? {};
    return {
        elements: [...nodes, ...edges],
        position: [viewport?.x ?? 0, viewport?.y ?? 0],
        zoom: viewport?.zoom ?? 1,
    };
};
exports.downgradeLegacyFlowExport = downgradeLegacyFlowExport;
