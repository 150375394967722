"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isObject = exports.getAtPath = void 0;
/**
 * A simple function to get a value from an object at a path. Falsey paths
 * and falsey input objects will return undefined. Comparable to lodash's
 * `get`, minus the array access support and path as array support.
 * @param object the object to get the value from
 * @param path the path to the value, in form of `a.b.c` for `{a: {b: {c: 1}}}`
 */
function getAtPath(object, path) {
    if (!object || !path || typeof path !== 'string') {
        return undefined;
    }
    const steps = path.split('.');
    let nextStep = steps.shift();
    let value = object;
    while (nextStep && value && isObject(value) && nextStep in value) {
        value = value[nextStep];
        nextStep = steps.shift();
    }
    // If we couldn't exhaust the path, it's invalid for `object`.
    if (nextStep || steps.length >= 1) {
        return undefined;
    }
    return value;
}
exports.getAtPath = getAtPath;
/**
 * Type guard to determine if the value can be used as a generica JavaScript
 * object. Performs no value or key checks only that the JavaScript runtime will
 * treat the value like an object.
 */
function isObject(o) {
    return typeof o === 'object' && !Array.isArray(o) && o !== null;
}
exports.isObject = isObject;
