/** helper copy that describes the `scrollTo` feature to the end user */
export const scrollToDescription = `Describes the distance to scroll within a container. anchorElId triggers scrollToElement,
   and elementId triggers scrollTo. Use '*' as an input value to disable the option that you
   don't want to use.
  `;
interface ScrollToProps {
  /**
   * the id or element selector of the target module to scroll within
   * @default ''
   */
  elementId?: string;
  /**
   * the id of the element to scroll to within the DOM.
   * @default null
   */
  anchorElId?: string | null;
  /**
   * the amount, in pixels, to scroll on the x-axis within an element
   * @default '0'
   */
  scrollX?: string;
  /**
   * the amount, in pixels, to scroll on the y-axis within an element
   * @default '0'
   */
  scrollY?: string;
}
/**
 * Accepts x/y coordinates or an `anchorElId` to scroll to within an element.
 * If an `anchorElId` is provided, this method will attempt to scroll to the matching
 * element using the native `scrollIntoView` method. Otherwise, it will attempt to
 * scroll to the destination instructed by `scrollX` and `scrollY` coordinates, within
 * the element that matches the `elementId` value provided.
 * @param elementId - the element's module id. `Default -` `''`
 * @param anchorElId - the id of the element to scroll to within the DOM. `Default -` `null`
 * @param scrollX - the amount, in pixels, to scroll on the x-axis. `Default -` `0`
 * @param scrollY - the amount, in pixels, to scroll on the y-axis. `Default -` `0`
 * @returns void
 */
export const scrollTo = ({
  elementId = '',
  anchorElId = null,
  scrollX = '0',
  scrollY = '0',
}: ScrollToProps): void => {
  // if an anchorElId is provided, attempt to scroll its matching element into view.
  // currently, the flow system doesn't pass empty values, thus we are checking if the
  // value of the `anchorElId.length` is more than a filler value like '*'
  if (anchorElId && anchorElId.trim().length > 1) {
    const anchorElement = document.getElementById(stripHash(anchorElId));
    anchorElement && anchorElement.scrollIntoView({behavior: 'smooth'});
    return;
  }

  // otherwise, attempt to scroll to an arbitrary location within an element
  const container = document.getElementById(stripHash(elementId));
  const top = Number(scrollY);
  const left = Number(scrollX);

  // if the container element isn't found in the DOM, or the stringified numbers aren't real
  // number values, return.
  if (!container || isNaN(top) || isNaN(left)) return;

  // scroll to the destination instructed
  container.scrollTo({
    top,
    left,
    behavior: 'smooth',
  });
};

/**
 * removes a '#' character if it is present at the beginning of the string provided.
 * This is intended to allow the `scrollTo` method to conveniently process element ids with hashes
 * @param str - the string to process
 * @returns - the processed string
 */
const stripHash = (str: string): string =>
  str[0] === '#' ? str.substring(1) : str;
