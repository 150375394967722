import {type JSONValue} from '../types';

/**
 * Recursively flattens an `input` value into its dot separated keys. Non-object
 * `input` values return an empty list of keys.
 */
export function getFlatKeys(
  input: JSONValue,
  prefix = '',
  currentKeys: string[] = []
): string[] {
  if (typeof input === 'object' && input !== null) {
    return currentKeys.concat(
      Object.entries(input).flatMap(([key, value]) => {
        const prefixedKey = prefix === '' ? key : `${prefix}.${key}`;
        if (typeof value === 'object' && value !== null) {
          return getFlatKeys(value, prefixedKey, currentKeys);
        } else {
          return [prefixedKey];
        }
      })
    );
  } else {
    return [];
  }
}
