import {useSubscription} from 'observable-hooks';
import {useCallback, useEffect} from 'react';
import {useShowInstructions} from './context/ShowInstructionsContainer';
import {GlobalInstructionSchema} from '../schemas/global-instruction-definition';

/**
 * Sets up handling for two situations:
 * 1. Listen for 'message' events on the `window.parent` and broadcast them as
 *    instructions
 * 1. Watch show instructions for a `window:parent:post-message` and use the
 *    [`postMessage` API](https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage)
 *    to pass the message data
 */
export function useParentMessageHandling(allowedOrigins: string[]): void {
  const {broadcast, observable} = useShowInstructions(GlobalInstructionSchema);
  // Listen for messages from the `window.parent`
  useEffect(() => {
    // Don't bother listening for messages if there are no allowed origins
    if (allowedOrigins.length === 0) {
      return;
    }
    const isGlobalListener = allowedOrigins.includes('*');
    const onMessageReceived = (e: MessageEvent<unknown>): void => {
      if (window === window.parent) {
        // Abort if current window is the same as `window.parent`
        return;
      } else if (e.source !== window.parent) {
        // Ensure that the message is coming from the parent frame aborting if
        // it is not
        return;
      } else if (!isGlobalListener && !allowedOrigins.includes(e.origin)) {
        // Ensure that the message is coming from the correct origin
        console.warn({
          allowedOrigins,
          eventOrigin: e.origin,
          msg: 'Message received from unexpected origin.',
        });
        return;
      } else {
        // Broadcast the instruction
        broadcast({
          type: 'Global:parent:on-message',
          meta: {message: e.data ?? null},
        });
      }
    };
    // Add the listener
    window.addEventListener('message', onMessageReceived);
    // On destruction, remove the listener
    return () => window.removeEventListener('message', onMessageReceived);
  }, [allowedOrigins, broadcast]);
  // Define a function to post the given message to the `window.parent`
  const postMessage = useCallback(
    (message: unknown): void => {
      if (allowedOrigins.includes('*')) {
        // If all origins are allowed, only send the message for all to hear
        window.parent?.postMessage(message, '*');
      } else if (allowedOrigins.includes(window.parent.origin)) {
        // If window.parent is a specified origin send to the parent origin
        window.parent?.postMessage(message, window.parent.origin);
      }
    },
    [allowedOrigins]
  );
  useSubscription(observable, {
    next: (instruction) => {
      switch (instruction.type) {
        case 'Global:parent:post-message':
          postMessage(instruction.meta.message);
          break;
      }
    },
  });
}
