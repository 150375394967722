/**
 * Keyword in the JSON schema which indicates an instruction should be ignored by
 * the flow editor.
 */
export const FLOW_IGNORE = '$lcd-flow-ignore';

/**
 * Version of the node associated with the `id` in the `NodeInstanceData`. This
 * non-standard schema property is intended to disambiguate between flow node
 * instances with the same `id` property but other differences.
 */
export const FLOW_NODE_VERSION = '$lcd-node-version';

/**
 * Keyword in the JSON schema which indicates an field should be private and
 * only editable on the module instance (not overridable in groups). A private
 * field is also not required for guest UI rendering.
 */
export const PRIVATE_FIELD_DATA = '$lcd-private-field-data';
