import {Type} from '@sinclair/typebox';
import type {TLiteral, TOptional, TString} from '@sinclair/typebox';
import {OptionalString} from './typebox-helpers';
import type {
  ParticipantInput,
  ParticipantInputSimple,
  TypeBoxObject,
  TypeBoxProperties,
  WhichTypes,
} from './typebox-helpers';

type AboutMeta = {about: TOptional<TString>};

type ParticipantOutput<
  Channel extends string,
  Which extends WhichTypes,
  Props extends TypeBoxProperties | Record<string, never> = Record<
    string,
    never
  >,
> = TypeBoxObject<{
  topic: TLiteral<Channel>;
  which: TLiteral<Which>;
  meta: Props extends Record<string, never>
    ? TypeBoxObject<AboutMeta>
    : TypeBoxObject<Props & AboutMeta>;
}>;

type ParticipateResult<
  Channel extends string,
  Which extends WhichTypes,
  Props extends TypeBoxProperties | Record<string, never>,
> =
  | ParticipantOutput<Channel, Which>
  | ParticipantOutput<Channel, Which, Props>;

/**
 * Higher-order function for making SubscribesTo and PublishesTo. Internally, it uses an overloaded function
 * because with a single type signature, the TS type returned will be too permissive in what fields it accepts
 * on the `meta` object.
 * @param which value of subscribe|publish to describe how this component interacts with this Instruction
 * @returns a function that defines the shape of and Instruction, including importantly its `meta` field
 */
// The type signatures of the returned functions are explicit, allow inference
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Participate = <Which extends WhichTypes>(which: Which) => {
  /**
   * Creates a participant message definition where the `meta` field contains
   * only the `about` property.
   * @param obj with topic and description data.
   * @returns the typebox schema based on `obj`.
   */
  function p<Channel extends string>(
    obj: ParticipantInputSimple<Channel>
  ): ParticipantOutput<Channel, Which>;

  /**
   * Creates a participant message definition where the `meta` field contains
   * the `about` property and the `meta` definition from `obj`.
   * @param obj with topic, description, and a schema for `meta`.
   * @returns the typebox schema based on `obj`.
   */
  function p<Channel extends string, Props extends TypeBoxProperties>(
    obj: ParticipantInput<Channel, Props>
  ): ParticipantOutput<Channel, Which, Props>;

  function p<Channel extends string, Props extends TypeBoxProperties>(
    obj: ParticipantInputSimple<Channel> | ParticipantInput<Channel, Props>
  ): ParticipateResult<Channel, Which, Props> {
    const baseFields = {
      topic: Type.Literal(obj.topic),
      which: Type.Literal(which),
    };
    const aboutSchema = OptionalString({
      title: 'About',
      description: 'Identifies the module where the instruction originated.',
    });
    if ('meta' in obj) {
      return Type.Object(
        {
          ...baseFields,
          meta: Type.Object(
            {...obj.meta, about: aboutSchema},
            {description: obj.description}
          ),
        },
        obj.options
      );
    } else {
      return Type.Object(
        {
          ...baseFields,
          meta: Type.Object(
            {about: aboutSchema},
            {description: obj.description}
          ),
        },
        obj.options
      );
    }
  }
  return p;
};

/**
 * Helper function to define the schema of an instruction the module "publishes" or "broadcasts".
 * @returns a schema object defining an instruction the component "publishes" or "broadcasts".
 */
export const PublishesTo = Participate('publish');

/**
 * Helper function to define the schema of an instruction to which the module "subscribes" or "receives".
 * @returns a schema object defining an instruction to which the module "subscribes" or "receives".
 */
export const SubscribesTo = Participate('subscribe');
