import {type JSONSchema7} from 'json-schema';
import {PRIVATE_FIELD_DATA} from './helper-constants';
import {isTypeBoxObject} from './typebox-helpers';

/**
 * Recursively mark all of the properties of `definition` with
 * `PRIVATE_FIELD_DATA`. **NOTE** Mutates the incoming `definition` by adding
 * the `PRIVATE_FIELD_DATA` key to each of the `properties`.
 */
export function markPrivateProperties(definition: JSONSchema7): void {
  if (isTypeBoxObject(definition)) {
    for (const value of Object.values(definition.properties)) {
      if (isTypeBoxObject(value)) {
        markPrivateProperties(value);
      } else {
        value[PRIVATE_FIELD_DATA] = true;
      }
    }
  }
}
