import {Observer, Subject, Subscription} from 'rxjs';

/**
 * A variant of `BehaviorSubject` that collects emitted arrays values so the
 * first time a subscription happens it gets the full set of previously emitted
 * values.
 */
export class CollectingBehaviorSubject<T> extends Subject<T[] | Error> {
  private collection: T[];
  private err: unknown | undefined = undefined;

  constructor() {
    super();
    const collection: T[] = [];
    this.collection = collection;
  }

  get value(): T[] {
    if (typeof this.err !== 'undefined') {
      throw this.err;
    } else if (this.closed) {
      throw new Error('CollectingBehaviorSubject is closed');
    }
    return this.collection;
  }

  error(err: unknown): void {
    this.err = err;
    super.error(err);
  }

  next(value: T[] | Error): void {
    if (Array.isArray(value)) {
      this.collection = this.collection.concat(value);
    }
    super.next(value);
  }

  /** Reset the buffered entries provided on new subscription. */
  reset(): void {
    this.collection = [];
  }

  /** @see https://rxjs.dev/api/index/class/Observable#subscribe- */
  subscribe(observer?: Partial<Observer<T[] | Error>>): Subscription;
  /** @see https://rxjs.dev/api/index/class/Observable#subscribe- */
  subscribe(next: (value: T[] | Error) => void): Subscription;
  /**
   * @deprecated Instead of passing separate callback arguments, use an observer
   * argument. Signatures taking separate callback arguments will be removed in
   * v8. Details: https://rxjs.dev/deprecations/subscribe-arguments
   */
  subscribe(
    next?: ((value: T[] | Error) => void) | null,
    error?: ((error: unknown) => void) | null,
    complete?: (() => void) | null
  ): Subscription;
  /** @see https://rxjs.dev/api/index/class/Observable#subscribe- */
  subscribe(
    observerOrNext?:
      | Partial<Observer<T[] | Error>>
      | ((value: T[] | Error) => void)
      | null,
    error?: ((error: unknown) => void) | null,
    complete?: (() => void) | null
  ): Subscription {
    if (typeof observerOrNext === 'function' || observerOrNext === null) {
      if (typeof observerOrNext === 'function') {
        observerOrNext(this.collection);
      }
      return super.subscribe(observerOrNext, error, complete);
    } else {
      if (typeof observerOrNext?.next === 'function') {
        observerOrNext.next(this.collection);
      }
      return super.subscribe(observerOrNext);
    }
  }
}
