import {Type} from '@sinclair/typebox';
import {PublishesTo, SubscribesTo} from './instruction-participate-module';
import {StringEnum} from './typebox-helpers';

export const moduleRender = Type.Optional(
  Type.Boolean({title: 'Module Visibility', default: true})
);

/** Schema definition for shared module-render instructions */
export const ModuleRenderInstructions = Type.Union([
  SubscribesTo({
    topic: `ModuleRender:change-module-visibility`,
    description: 'Toggle the module visibility',
    meta: {
      type: StringEnum(['show', 'hide', 'toggle'], {
        // TODO: implement dropdown
        title: 'Type (show, hide, toggle)',
        description: 'type of visibility change',
      }),
    },
    options: {title: 'Module: Change Visibility'},
  }),
  PublishesTo({
    topic: `ModuleRender:module-visibility-changed`,
    description: 'Module visibility has changed',
    meta: {
      moduleRender: moduleRender,
    },
    options: {title: 'Module: Visibility Changed'},
  }),
]);
