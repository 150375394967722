/**
 * Custom `Error` implementation indicating a configuration issue.
 */
export class RenderError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, RenderError.prototype);
  }
}

/**
 * Custom `Error` implementation indicating an error registering a module.
 */
export class RegistryError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, RegistryError.prototype);
  }
}
