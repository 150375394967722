import {Type} from '@sinclair/typebox';
import {
  GlobalPublishesTo,
  GlobalSubscribesTo,
} from '../helpers/instruction-participate-global';

export const GlobalInstructionSchema = Type.Union([
  GlobalPublishesTo({
    topic: `Global:content:on-resize`,
    description: 'Broadcast when the content resizes',
    meta: {
      height: Type.Number({
        title: 'Height',
        description: 'Height of the content in pixels',
      }),
      width: Type.Number({
        title: 'Width',
        description: 'Width of the content in pixels',
      }),
    },
    options: {title: 'Content: On Resize'},
  }),
  GlobalPublishesTo({
    topic: `Global:parent:on-message`,
    description: 'Broadcast when the window.parent receives a message',
    meta: {
      message: Type.Optional(
        Type.Unknown({
          title: 'Message',
          description: 'The message received from the window.parent',
        })
      ),
    },
    options: {title: 'Parent: On Message Received'},
  }),
  GlobalSubscribesTo({
    topic: `Global:parent:post-message`,
    description:
      'Used internally by ShowInstructionsProvider to determine current page for "about" selectors.',
    meta: {
      message: Type.Optional(
        Type.Unknown({
          title: 'Message',
          description: 'The message to send to the window.parent',
        })
      ),
    },
    options: {title: 'Parent: Post Message'},
  }),
  GlobalPublishesTo({
    topic: `Global:state:on-change`,
    description: 'Broadcast when global state, including current page, changes',
    meta: {},
    options: {title: 'Show State: On Change'},
  }),
  GlobalPublishesTo({
    topic: `Global:variable:on-set`,
    description: 'Broadcast when a value in global state is set',
    meta: {},
    options: {
      title: 'Show Variable: On Change',
      '$lcd-flow-ignore': true,
      '$lcd-node-version': 2,
    },
  }),
]);
