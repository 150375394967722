"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractInstruction = void 0;
const instruction_1 = require("./instruction");
/**
 * Creates an appropriate `Instruction` object from the `ApiInstruction` input,
 * ensuring `meta` is a `JSONObject` and `type` is namespaced.
 */
function extractInstruction(action) {
    const type = (0, instruction_1.isNamespaced)(action.kind)
        ? action.kind
        : `unknown:${action.kind}`;
    const meta = typeof action.meta === 'object' &&
        !Array.isArray(action.meta) &&
        action.meta !== null
        ? action.meta
        : {};
    // Putting undefined into the instruction makes it not pass a check for
    // JSONValue, so omit the value if it is not defined
    if (typeof action.createdAt === 'undefined') {
        return { meta, type };
    }
    else {
        return { createdAt: action.createdAt, meta, type };
    }
}
exports.extractInstruction = extractInstruction;
