import {useCallback, useEffect, useRef} from 'react';
import {GlobalInstructionSchema} from '../schemas/global-instruction-definition';
import {useShowInstructions} from './context/ShowInstructionsContainer';
import {getFlows} from './context/transformations/converter';
import {useManagedRef} from './useManagedRef';

/**
 * Sets up handling for the situation where the root container has changed size
 * by creating a `ResizeObserver` for the given `rootElement` but only observe
 * `rootElement` if flows exist for the relevant instruction.
 */
export function useContentResizeHandling(
  domainName?: string,
  rootElement?: HTMLElement
): void {
  const {broadcast} = useShowInstructions(GlobalInstructionSchema);
  // Put the `rootElement` in a ref because if it gets updated the callback in
  // the `ResizeObserver` needs to be able to "see" the correct element to
  // perform filtering
  const rootElementRef = useManagedRef(rootElement);
  // Manage the size object as a ref rather than state so the hook doesn't
  // trigger rerenders in consuming component(s). This is a concern because the
  // consuming component(s) are likely to be high up in the render tree and a
  // rerender would cascade through the whole tree
  const sizeRef = useRef({height: 1, width: 1});
  const broadcastUpdate = useCallback(
    (nextHeight: number, nextWidth: number): void => {
      // NOTE: It is unexpected, but correct, to create the size object
      // twice; a likely use case for the `meta` of the instruction is to
      // pass it to another window via the `postMessage` API and the
      // `postMessage` API, when used with objects, passes ownership of the
      // objects to receiving frames meaning the next execution of the
      // `ResizeObserver` callback would not be able to read the object's
      // properties because the backstage frame would no longer own the
      // JavaScript object which would result in an error.
      broadcast({
        type: 'Global:content:on-resize',
        meta: {height: nextHeight, width: nextWidth},
      });
      // Update sizeRef for the next pass
      sizeRef.current = {height: nextHeight, width: nextWidth};
    },
    [broadcast]
  );
  // `ResizeObserver` does not exist in test jsdom environment, use a mock in
  // situations where it is unavailable
  const SizeObserver =
    typeof ResizeObserver !== 'undefined' ? ResizeObserver : MockResizeObserver;
  const resizeObserverRef = useRef(
    new SizeObserver((entries) => {
      const rootElement = rootElementRef.current;
      const currentHeight = sizeRef.current.height;
      const currentWidth = sizeRef.current.width;
      for (const entry of entries) {
        const contentBox = entry.contentBoxSize.reduce(
          (_, size) => ({height: size.blockSize, width: size.inlineSize}),
          {height: 0, width: 0}
        );
        const nextHeight = contentBox.height;
        const nextWidth = contentBox.width;
        if (entry.target !== rootElement) {
          // If target isn't the passed in `rootElement` then move along
          continue;
        } else if (currentHeight !== nextHeight || currentWidth !== nextWidth) {
          broadcastUpdate(nextHeight, nextWidth);
        }
      }
    })
  );
  // Observe `rootElement` with `ResizeObserver` in Ref
  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    const instructionSchema = GlobalInstructionSchema.anyOf.find(
      (s) => s.properties.topic.const === 'Global:content:on-resize'
    );
    const flowData = getFlows(domainName);
    const isFlowDataEmpty = Object.keys(flowData).length === 0;
    const topic = instructionSchema?.properties.topic.const;
    const flows = typeof topic === 'string' ? flowData[topic] ?? [] : [];
    if (typeof rootElement === 'undefined') {
      // No rootElement, nothing to do
      return;
    } else if (flows.length > 0 || isFlowDataEmpty) {
      // Observe if there are flows for `content:on-resize` or if no flows are
      // defined at all. The first time a site loads and this hook mounts
      // `getFlows` may return an empty object, in that case this still needs to
      // observe so flows, once loaded, will work as expected
      resizeObserver.observe(rootElement);
    }
    return () => {
      resizeObserver.unobserve(rootElement);
    };
  }, [domainName, rootElement]);
}

class MockResizeObserver implements ResizeObserver {
  disconnect(): void {
    // nothing to do in the mock
  }
  observe(
    _target: Element,
    _options?: ResizeObserverOptions | undefined
  ): void {
    // nothing to do in the mock
  }
  unobserve(_target: Element): void {
    // nothing to do in the mock
  }
}
