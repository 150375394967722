import type {TGroup} from '@backstage/utils/rjsf-helpers';
import {
  Kind,
  Type,
  type Static,
  type TNever,
  type TObject,
  type TSchema,
  type TUnion,
} from '@sinclair/typebox';
import type {SetOptional} from 'type-fest';
import type {AnalyticsInstructionMask, JSONObject} from '../types';
import type {TypedComponentAdmin} from '../types/component-admin';
import {
  AnimationInstructions,
  animationStates,
  animationUi,
} from './animation-helpers';
import type {
  DeriveInstructionType,
  InstructionSchema,
} from './instruction-schema';
import {styleAttr, styleAttrRef, styleAttrUi} from './style-helpers';
import {ModuleRenderInstructions, moduleRender} from './module-render-helpers';

/** Extract the type of module properties from `TypedComponentAdmin` */
export type SchemaTypeHelper<CA> = CA extends TypedComponentAdmin<
  infer SettingsSchema,
  // biome-ignore lint/correctness/noUnusedVariables: language parser needs
  infer InstructionsSchema
>
  ? Static<SettingsSchema>
  : never;

/** Extract the type of module instructions from `TypedComponentAdmin` */
export type SchemaInstructionsHelper<CA> = CA extends TypedComponentAdmin<
  // biome-ignore lint/correctness/noUnusedVariables: language parser needs
  infer SettingsSchema,
  infer InstructionsSchema
>
  ? DeriveInstructionType<InstructionsSchema>
  : never;

/**
 * Type Alias to manipulate module settings, adding `Key` with `Setting` to the
 * existing `Schema`.
 */
type AddSettingsProperty<
  Schema,
  Key extends string,
  Setting extends TSchema,
> = Schema extends TObject<infer Properties>
  ? TObject<Properties & Record<Key, Setting>>
  : TObject<Record<Key, Setting>>;

/**
 * Type Alias to maniuplate instructions adding `ToAdd` to the existing
 * `Instructions` schema.
 */
type CombineInstructions<
  Instructions,
  ToAdd extends TUnion,
> = Instructions extends TUnion<infer Existing>
  ? TUnion<[...Existing, ...ToAdd['anyOf']]>
  : ToAdd;

/**
 * Type alias representing the result of the `#build` method on the
 * `ComponentAdminBuilder` indicating instructions may be empty if they have a
 * type of `TNever`.
 */
type BuildResult<T> = T extends ComponentAdminBuilder<
  infer Schema,
  infer Instructions,
  infer DefaultFieldData,
  infer PrivateSchema
>
  ? Instructions extends TUnion
    ? TypedComponentAdmin<Schema, Instructions, DefaultFieldData, PrivateSchema>
    : Instructions extends TNever
      ? SetOptional<
          TypedComponentAdmin<
            Schema,
            TUnion<InstructionSchema[]>,
            DefaultFieldData,
            PrivateSchema
          >,
          'instructions'
        >
      : never
  : never;

class ComponentAdminBuilder<
  Schema extends TObject,
  Instructions extends TUnion | TNever,
  DefaultFieldData extends Static<Schema> = Static<Schema>,
  PrivateSchema extends TObject = TObject,
> {
  #analyticsMask?: AnalyticsInstructionMask<Instructions>;
  #details: TypedComponentAdmin<
    Schema,
    Instructions,
    DefaultFieldData,
    PrivateSchema
  >;
  #defaultFieldData: DefaultFieldData;
  #instructions: Instructions;
  #privateSchema?: PrivateSchema;
  #schema: Schema;
  #uiSchema?: JSONObject & {
    'ui:template'?: string;
    'ui:groups'?: TGroup;
  };

  constructor(
    initial: TypedComponentAdmin<
      Schema,
      Instructions,
      DefaultFieldData,
      PrivateSchema
    >
  ) {
    this.#analyticsMask = initial.analyticsInstructionMask;
    this.#details = initial;
    this.#defaultFieldData = initial.defaultFieldData;
    this.#instructions = initial.instructions;
    this.#privateSchema = initial.privateSchema;
    this.#schema = initial.schema;
    this.#uiSchema = initial.uiSchema;
  }

  /**
   * Create the strongly typed component definition with any manipulations from
   * builder methods applied.
   */
  build(): BuildResult<ReturnType<(typeof this)['withModuleRender']>> {
    const _this = this.withModuleRender();

    // Unable to work out the types necessary for the return value here,
    // presumably because `BuildResult` is a conditional type, and so the type
    // assertion is necessary.
    return {
      analyticsInstructionMask: _this.#analyticsMask,
      category: _this.#details.category,
      defaultFieldData: _this.#defaultFieldData,
      description: _this.#details.description,
      id: _this.#details.id,
      instructions:
        _this.#instructions[Kind] === 'Union' ? _this.#instructions : undefined,
      name: _this.#details.name,
      positionRestrictions: _this.#details.positionRestrictions,
      privateSchema: _this.#privateSchema,
      reactName: _this.#details.reactName,
      schema: _this.#schema,
      slotConfiguration: _this.#details.slotConfiguration,
      slug: _this.#details.slug,
      uiSchema: _this.#uiSchema,
      version: _this.#details.version,
    } as unknown as BuildResult<ReturnType<(typeof this)['withModuleRender']>>;
  }

  /**
   * Applies the given `mask` to the eventual `ComponentAdmin` definition.
   *
   * **WARNING** If called multiple times only the _latest_ mask will be
   * included in the definition.
   */
  withAnalyticsInstructionMask(
    mask: AnalyticsInstructionMask<Instructions>
  ): this {
    this.#analyticsMask = mask;
    return this;
  }

  /**
   * Adds properties and instructions necessary for animation functionality to
   * the `ComponentAdmin` definition.
   */
  withAnimationStates(): ComponentAdminBuilder<
    AddSettingsProperty<Schema, 'animationStates', typeof animationStates>,
    CombineInstructions<Instructions, typeof AnimationInstructions>,
    DefaultFieldData,
    PrivateSchema
  > {
    const instance = new ComponentAdminBuilder<
      AddSettingsProperty<Schema, 'animationStates', typeof animationStates>,
      CombineInstructions<Instructions, typeof AnimationInstructions>,
      DefaultFieldData,
      PrivateSchema
    >({
      ...this.#details,
      analyticsInstructionMask: this.#analyticsMask,
      defaultFieldData: this.#defaultFieldData,
      // @ts-expect-error shape of instructions can't be spread into union in a
      // type safe way
      instructions:
        this.#instructions[Kind] === 'Union'
          ? Type.Union([
              ...this.#instructions.anyOf,
              ...AnimationInstructions.anyOf,
            ])
          : AnimationInstructions,
      // @ts-expect-error there's a type mismatch due to conditional types not
      // being assignable
      // *NOTE*: `Type.Intersect` does not preserve additional properties passed
      // to the schema, like `dependencies`, which breaks some module schemas.
      schema: Object.assign({}, this.#schema, {
        properties: {...this.#schema.properties, animationStates},
      }),
      uiSchema: {
        ...this.#uiSchema,
        ...animationUi,
      },
    });
    return instance;
  }

  /**
   * Adds properties necessary for custom styling functionality to the
   * `ComponentAdmin` definition.
   */
  withStyles(): ComponentAdminBuilder<
    AddSettingsProperty<Schema, 'styleAttr', typeof styleAttr>,
    Instructions,
    DefaultFieldData,
    PrivateSchema
  > {
    const instance = new ComponentAdminBuilder<
      AddSettingsProperty<Schema, 'styleAttr', typeof styleAttr>,
      Instructions,
      DefaultFieldData,
      PrivateSchema
    >({
      ...this.#details,
      analyticsInstructionMask: this.#analyticsMask,
      defaultFieldData: this.#defaultFieldData,
      // @ts-expect-error there's a type mismatch due to conditional types not
      // being assignable
      // *NOTE*: `Type.Intersect` does not preserve additional properties passed
      // to the schema, like `dependencies`, which breaks some module schemas.
      schema: Object.assign({}, this.#schema, {
        properties: {
          ...this.#schema.properties,
          styleAttr: Type.Optional(styleAttrRef()),
        },
        $defs: {...this.#schema.$defs, styleAttr},
      }),
      uiSchema: {
        ...this.#uiSchema,
        ...styleAttrUi,
      },
    });
    return instance;
  }

  /**
   * Adds properties and instructions necessary for module render functionality to
   * the `ComponentAdmin` definition.
   */
  withModuleRender(): ComponentAdminBuilder<
    AddSettingsProperty<Schema, 'moduleRender', typeof moduleRender>,
    CombineInstructions<Instructions, typeof ModuleRenderInstructions>,
    DefaultFieldData,
    PrivateSchema
  > {
    const uiSchema = this.#uiSchema;

    // handle tabs implementations
    if (uiSchema && 'ui:groups' in uiSchema) {
      const propertiesSection =
        uiSchema?.['ui:groups']?.sections.filter(
          (s) => typeof s[0] === 'string' && s[0] === 'Properties'
        )[0] ?? [];

      const properties = propertiesSection[1]?.[0];

      if (typeof properties === 'object' && 'sections' in properties) {
        properties.sections = [
          ['Module Visibility', ['moduleRender']],
          ...properties.sections,
        ];
      } else {
        propertiesSection[1] = [
          {
            sections: [['Module Visibility', ['moduleRender']]],
            'ui:template': 'accordion',
          },
        ];
      }
    } else {
      if (typeof uiSchema !== 'undefined') {
        if ('ui:order' in uiSchema) {
          const order = uiSchema['ui:order'];
          Array.isArray(order) && order.unshift('moduleRender');
        } else {
          uiSchema['ui:order'] = ['moduleRender', '*'];
        }
      }
    }

    const instance = new ComponentAdminBuilder<
      AddSettingsProperty<Schema, 'moduleRender', typeof moduleRender>,
      CombineInstructions<Instructions, typeof ModuleRenderInstructions>,
      DefaultFieldData,
      PrivateSchema
    >({
      ...this.#details,
      analyticsInstructionMask: this.#analyticsMask,
      defaultFieldData: this.#defaultFieldData,
      // @ts-expect-error shape of instructions can't be spread into union in a
      // type safe way
      instructions:
        this.#instructions[Kind] === 'Union'
          ? Type.Union([
              ...this.#instructions.anyOf,
              ...ModuleRenderInstructions.anyOf,
            ])
          : ModuleRenderInstructions,
      // @ts-expect-error there's a type mismatch due to conditional types not
      // being assignable
      // *NOTE*: `Type.Intersect` does not preserve additional properties passed
      // to the schema, like `dependencies`, which breaks some module schemas.
      schema: Object.assign({}, this.#schema, {
        properties: {...this.#schema.properties, moduleRender},
      }),
      uiSchema,
    });
    return instance;
  }
}

/**
 * Create a builder for a `ComponentAdmin` instance with inferred instruction
 * types. The builder enables composing a `ComponentAdmin` instance
 * semi-fluently.
 */
export function createComponentAdmin<
  Schema extends TObject,
  Instructions extends TUnion | TNever,
  DefaultFieldData extends Static<Schema> = Static<Schema>,
  PrivateSchema extends TObject = TObject,
>(
  props: TypedComponentAdmin<
    Schema,
    Instructions,
    DefaultFieldData,
    PrivateSchema
  >
): ComponentAdminBuilder<
  Schema,
  Instructions,
  DefaultFieldData,
  PrivateSchema
> {
  return new ComponentAdminBuilder(props);
}
