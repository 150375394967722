import {filter, map, Subject} from 'rxjs';
import {Registry} from '../../../registry';
import {AnalyticsInstructionMask} from '../../../types';

/** A "dictionary" of instruction topic / type to masking functions */
const masks: Record<string, AnalyticsInstructionMask> = {};

/**
 * Gets a masking function immediately for `topic`. If there is not a masking
 * function for `topic` an identity function is provided.
 * @param topic an instruction topic for which the masking function will be
 * retrieved.
 * @returns the registered function if it exists or an identity function if it
 * does not.
 */
export function getMask(topic: string): AnalyticsInstructionMask;
/**
 * Gets a handler function for `topic` waiting until one is registered if one
 * does not already exist.
 * @param topic of the function to retrieve.
 * @returns the function registered for `topic`.
 */
export function getMask(
  topic: string,
  mode: 'async'
): Promise<AnalyticsInstructionMask>;
export function getMask(
  topic: string,
  mode: 'async' | 'sync' = 'sync'
): AnalyticsInstructionMask | Promise<AnalyticsInstructionMask> {
  if (mode === 'sync') {
    return getMaskSync(topic);
  }
  return new Promise<AnalyticsInstructionMask>((resolve) => {
    const mask = masks[topic];
    if (mask) {
      resolve(mask);
    } else {
      const subscription = maskBus
        .asObservable()
        .pipe(
          filter((ev) => ev.type === 'register' && ev.detail.key === topic),
          map((ev) => masks[ev.detail.key])
        )
        .subscribe((fn) => {
          if (typeof fn === 'function') {
            resolve(fn);
            subscription.unsubscribe();
          }
        });
    }
  });
}

/** Simple identity function returning exactly what is passed in. */
function identity<T>(input: T): T {
  return input;
}

/**
 * Gets a masking function immediately for `topic`. If there is not a masking
 * function for `topic` an identity function is provided.
 * @param topic of the function to retrieve.
 * @returns the registered function if it exists or an identity function if it
 * does not.
 */
function getMaskSync(topic: string): AnalyticsInstructionMask {
  const fn = masks[topic];
  if (typeof fn !== 'undefined') {
    return fn;
  } else {
    return identity;
  }
}

/** Register event, to be emitted when a new entry is added to `masks`. */
interface MaskRegisterEvent {
  type: 'register';
  detail: {
    key: string;
  };
}

/** Union of change notifications published on the `masksBus`. */
type MaskEvent = MaskRegisterEvent;

const maskBus = new Subject<MaskEvent>();

Registry.on('register', (c) => {
  const mask = c.analyticsInstructionMask ?? identity;
  const instructionSchemas = c.instructions?.anyOf ?? [];
  for (const schema of instructionSchemas) {
    const topic = schema.properties.topic.const;
    if (typeof masks[topic] !== 'undefined') {
      continue;
    } else {
      masks[topic] = mask;
      // Notify if we changed `masks`
      maskBus.next({type: 'register', detail: {key: c.id}});
    }
  }
});
