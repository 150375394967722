import {Type} from '@sinclair/typebox';
import {
  GlobalPublishesTo,
  GlobalSubscribesTo,
} from '../helpers/instruction-participate-global';

/**
 * Describes the schema for instructions which interact with the authenticated
 * Guest system.
 */
export const GuestInstructionSchema = Type.Union([
  GlobalPublishesTo({
    topic: 'Guest:on-logout',
    description: 'Published when the guest has been logged out',
    meta: {},
    options: {title: 'Guest: On Logout'},
  }),
  GlobalSubscribesTo({
    topic: 'Guest:logout',
    description: 'Command to logout the current guest',
    meta: {},
    options: {title: 'Guest: Logout'},
  }),
]);
