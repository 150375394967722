import {Fragment, type FC, type PropsWithChildren} from 'react';
import {useContentResizeHandling} from '../useContentResizeHandling';
import {useParentMessageHandling} from '../useParentMessageHandling';

export interface GlobalInstructionHandlingProviderProps {
  /** Origins in which the site may be embedded */
  allowedEmbedOrigins?: string[];
  /** Domain on which the `showId` was viewed */
  domainName?: string;
  /**
   * Element at the root of the application, if necessary it will be observed
   * with a [`ResizeObserver`][resize-observer] to trigger `:on-resize`
   * instructions
   *
   * [resize-observer]: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
   */
  rootElement?: HTMLElement;
}

export const GlobalInstructionHandlingProvider: FC<
  PropsWithChildren<GlobalInstructionHandlingProviderProps>
> = (props) => {
  const {allowedEmbedOrigins = [], children, domainName, rootElement} = props;
  useContentResizeHandling(domainName, rootElement);
  useParentMessageHandling(allowedEmbedOrigins);
  return <Fragment>{children}</Fragment>;
};
