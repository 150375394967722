/**
 * Function to standardize the way keys are created for storage of instruction
 * variables that are "local only".
 * @param showId used to scope the variable
 * @param key of the variable; if not provided returns only the prefix
 * @returns the key for the local variable.
 */
export const localVariable = (showId: string, key?: string): string =>
  `${showId.slice(0, 8)}/local/${key ?? ''}`;

/**
 * Function to standardize the way keys are created for storage of instruction
 * variables that are "global".
 * @param showId used to scope the variable
 * @param key of the variable; if not provided returns only the prefix
 * @returns the key for the global variable.
 */
export const globalVariable = (showId: string, key?: string): string =>
  `${showId.slice(0, 8)}/${key ?? ''}`;
