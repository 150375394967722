export interface MousePosition {
  x: number;
  y: number;
}

/**
 * Gets the mouse position
 * @param e the mouse event
 * @returns an object with the x and y position of the mouse
 */
export const getMousePos = (e: MouseEvent): MousePosition => {
  let posx = 0;
  let posy = 0;

  if (!e) return {x: 0, y: 0};
  if (e.pageX || e.pageY) {
    posx = e.pageX - document.documentElement.scrollLeft;
    posy = e.pageY - document.documentElement.scrollTop;
  } else if (e.clientX || e.clientY) {
    const bodyEls = document.getElementsByTagName('body');
    const bodyEl = bodyEls[0];
    if (typeof bodyEl === 'undefined') {
      posx = 0;
      posy = 0;
    } else {
      posx =
        e.clientX + bodyEl.scrollLeft + document.documentElement.scrollLeft;
      posy = e.clientY + bodyEl.scrollTop + document.documentElement.scrollTop;
    }
  }
  return {x: posx, y: posy};
};
