import {type ModuleIdentifiers} from '@backstage/api-types';
import {
  Kind,
  ObjectOptions,
  SchemaOptions,
  StringOptions,
  TAnySchema,
  TNull,
  TObject,
  TOptional,
  TProperties,
  TSchema,
  TString,
  TUnion,
  TUnsafe,
  Type,
} from '@sinclair/typebox';
import {JSONSchema7} from 'json-schema';
import {FLOW_IGNORE, FLOW_NODE_VERSION} from './helper-constants';

export {type ModuleIdentifiers} from '@backstage/api-types';

export function OptionalString(title: string): TOptional<TString>;

export function OptionalString(options: StringOptions): TOptional<TString>;

export function OptionalString(
  options: string | StringOptions
): TOptional<TString> {
  if (typeof options === 'string') {
    return Type.Optional(Type.String({title: options}));
  } else {
    return Type.Optional(Type.String(options));
  }
}

export const Nullable = <T extends TypeBoxSchema>(
  type: T
): TUnion<[T, TNull]> => Type.Union([type, Type.Null()]);

/** Build a `TUnion` where there is only one membmer. */
export const SingleUnion = <T extends TSchema>(
  anyOf: T,
  options?: SchemaOptions
): TUnion<[T]> => {
  // `static` and `params` are used for type inference and do not get assigned
  const base: Omit<TUnion<[T]>, 'params' | 'static'> = {
    anyOf: [anyOf],
    [Kind]: 'Union',
    ...options,
  };
  // assert as `TUnion` so type inferernce works properly with the `Static` type
  // from `@sinclair/typebox`
  return base as TUnion<[T]>;
};
export type WhichTypes = 'subscribe' | 'publish';

export type ParticipantInputSimple<Channel extends string> = {
  /** with which we are interacting. */
  topic: Channel;
  /**
   * to be associated with the `meta` shape of the schema.
   * @default ''
   */
  description?: string;
  /** to use with the returned schema object. */
  options?: ObjectOptions & FlowEditorOptions;
};

export type ParticipantInput<
  Channel extends string,
  Props extends TProperties,
> = ParticipantInputSimple<Channel> & {
  /** to include in the `meta` shape of the schema. NOTE: already includes `about` */
  meta: Props;
};

interface FlowEditorOptions {
  /**
   * If `true` the instruction node will not appear in the site-builder flow
   * editor.
   */
  [FLOW_IGNORE]?: boolean;
  /**
   * The version of the flow node with this type, this can be useful in
   * disambiguating during flow upgrade scripts.
   */
  [FLOW_NODE_VERSION]?: number;
}

/**
 * Supplies the strict set of properties of ModuleIdentifiers in a specific
 * order so `JSON.stringify` is deterministic.
 * @param moduleIdentifiers ...or any object that implements `ModuleIdentifiers`
 * @returns ModuleIdentifiers
 */
export const extractModuleIdentifiers = <Input extends ModuleIdentifiers>(
  moduleIdentifiers: Input
): ModuleIdentifiers => {
  return {
    id: moduleIdentifiers.id,
    mid: moduleIdentifiers.mid,
    cid: moduleIdentifiers.cid,
    gid: moduleIdentifiers.gid ?? null,
    groupModuleId: moduleIdentifiers.groupModuleId ?? null,
    name: moduleIdentifiers.name,
    path: moduleIdentifiers.path?.slice(0) ?? [],
  };
};

/**
 * Generates an object with "dummy" values that quacks like `ModuleIdentifiers`.
 * @private exported for testing and for site-builder which doesn't run instructions.
 */
export const dummyModuleIdentifiers = (options?: {
  isGroup?: boolean;
}): ModuleIdentifiers => {
  return {
    id: 'id69999997b7b',
    mid: 'd6999999-7b7b-41a6-8f9e-a72e1ffe7bea',
    cid: 'c2999999-736d-4961-a883-ba17628dc8a7',
    gid: options?.isGroup ? '74999999-ad2b-41c0-b2ca-fdd1054136bf' : null,
    groupModuleId: options?.isGroup
      ? '85999999-ad2b-41c0-b2ca-fdd1054136bf'
      : null,
    name: 'name',
    path: [],
  };
};

/**
 * Makes a string union in Typescript, which will be represented as a dropdown
 * in RJSF forms via an enum representation in JSON Schema.
 * @param vals readonly array 🚨🚨🚨 MUST BE typed as literal values. Eg, `['a', 'b'] as const`
 * @param options optional CustomOptions object. Usually just use `title`
 * @returns Typebox type definition that translates to use of the `enum` key in JSON Schema
 */
export const StringEnum = <Member extends string>(
  vals: readonly Member[],
  options?: SchemaOptions
): TUnsafe<Member[][number]> =>
  Type.Unsafe<Member[][number]>({...options, enum: vals});
/**
 * Union of types from `@sinclair/typebox` which extend `TSchema`.
 */
export type TypeBoxSchema = TAnySchema;

/** Replacement of `TProperties` which extends the union of `TSchema` types. */
export type TypeBoxProperties = {[key: string]: TypeBoxSchema};

/** Replacement of `TObject` which uses the union of `TypeBoxProperties` rather
 * than `TProperties`.
 */
export type TypeBoxObject<T extends TypeBoxProperties> = TObject<T>;

/**
 * Check if the given `JSONSchema7` can be treated as a `TypeBoxObject`.
 */
export function isTypeBoxObject(input: JSONSchema7): input is TObject {
  // "upcast" or lose type information, changing it to a generic object type to
  // safely look for keys which do not exist in the `JSONSchema7` type
  const upcast = input as Record<string | symbol | number, unknown>;
  return input.type === 'object' && Kind in input && upcast[Kind] === 'Object';
}
