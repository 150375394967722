import {
  Type,
  type SchemaOptions,
  type TRef,
  type TString,
} from '@sinclair/typebox';

/**
 * Create a Ref for the style attribute with the given options. This function is
 * exposed rather than the path in order to maintain type safety when the types
 * are inferred via the `Static` type helper.
 */
export const styleAttrRef = (options?: SchemaOptions): TRef<TString> =>
  Type.Ref('#/$defs/styleAttr', options);

/**
 * Definition for custom styles. If used as a Ref value must be included in a
 * `definitions` block of the outermost `Type.Object`.
 *
 * @example Usage in a `Type.Object`
 *
 * ```ts
 * Type.Object({styleAttr})
 * ```
 *
 * @example Usage as a Ref
 *
 * ```ts
 * Type.Object(
 * {myStyles: Type.Optional(styleAttrRef())},
 * {$defs: {styleAttr}}
 * );
 * ```
 */
export const styleAttr = Type.Optional(
  Type.String({
    $id: 'style_attribute_definition',
    title: 'Custom Styles',
    default: '',
    description:
      'Use `.lcd-preview-only & { /* styles */ }` to apply styles specific to the layout editor.',
  })
);

export const styleAttrUi = {
  styleAttr: {
    'ui:widget': 'modalTextareaWidget',
    'ui:options': {
      buttonTitle: 'Edit',
      editor: 'CodeMirror',
    },
  },
};
