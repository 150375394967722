"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTransformations = void 0;
/**
 * Returns a list of transformations, each of which is a set of nodes and edges
 * for a single flow, plus some other references.
 * @param tab The content of a FlowTab record that contains all the flows and
 * nodes of a tab.
 * @returns
 */
const getTransformations = (tab) => {
    const transformations = [];
    const { edgeLookup, nodeLookup } = makeLookups(tab.data);
    /**
     * A `Map` of all nodeIds-to-nodes available for assignment to a transformation.
     * As each is assigned to a transformation, it will be removed from this Map.
     */
    const availableNodes = new Map(Object.entries(nodeLookup));
    /**
     * A `Map` of all edgeIds-to-edges available for assignment to a transformation.
     * As each is assigned to a transformation, it will be removed from this Map.
     */
    const availableEdges = new Map(Object.entries(edgeLookup));
    const kit = {
        availableNodes,
        availableEdges,
        nodeLookup,
    };
    /**
     * Each iteration over the set of all edges should reduce the edge use count.
     * If the number is the same as the last iteration, we're in an infinite loop.
     */
    let lastSize = Infinity;
    while (availableEdges.size > 0 && lastSize !== availableEdges.size) {
        lastSize = availableEdges.size;
        // Grab the first available edge to start traversal and identification of
        // the entire flow.
        const nextEdge = availableEdges.values().next();
        const edge = nextEdge.done !== true ? nextEdge.value.element : undefined;
        if (!edge) {
            continue;
        }
        const transformation = {
            // TODO: make name unique for debugging. It is not used anywhere programmatically.
            name: 'transformation',
            edgeLookup: {},
            nodes: {},
        };
        transformations.push(transformation);
        availableEdges.delete(edge.id);
        transformation.edgeLookup[edge.id] = { element: edge };
        addNodeAndEdges(transformation, nodeLookup[edge.source], kit);
        addNodeAndEdges(transformation, nodeLookup[edge.target], kit);
    }
    return transformations;
};
exports.getTransformations = getTransformations;
/**
 * Adds the node and all of its edges to the passed transformation, then
 * recurses to add all the other nodes and edges to identify the entire flow.
 * @param transformation The set of nodes and edges that make up a flow
 * @param nodeMapper The reference to the node that is being added to the
 * transformation
 * @param kit Collections for reference and mutation that will be used in the
 * recursion. They're separated out here to simplify the recursion signature.
 */
const addNodeAndEdges = (transformation, nodeMapper, kit) => {
    const { availableNodes, availableEdges, nodeLookup } = kit;
    const node = nodeMapper?.element;
    if (node && availableNodes.has(node.id)) {
        transformation.nodes[node.id] = { element: node, transformation };
        availableNodes.delete(node.id);
        // This involves fewer operations than mutating arrays and filtering.
        const edges = [];
        availableEdges.forEach((edge) => {
            if (edge.element.source === node.id || edge.element.target === node.id) {
                edges.push(edge);
                availableEdges.delete(edge.element.id);
            }
        });
        // Run this separate from the forEach to ensure all the edges are
        // removed from the availableEdges set before recursing.
        edges.forEach(({ element: edge }) => {
            transformation.edgeLookup[edge.id] = { element: edge };
            addNodeAndEdges(transformation, nodeLookup[edge.target], kit);
            addNodeAndEdges(transformation, nodeLookup[edge.source], kit);
        });
    }
};
const makeLookups = (obj) => {
    return (obj?.elements || []).reduce((a, c) => {
        if ('source' in c) {
            a.edgeLookup[c.id] = { element: c };
        }
        else {
            a.nodeLookup[c.id] = { element: c };
        }
        return a;
    }, { nodeLookup: {}, edgeLookup: {} });
};
