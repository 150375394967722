import {Type} from '@sinclair/typebox';
import {
  GlobalPublishesTo,
  GlobalSubscribesTo,
} from '../helpers/instruction-participate-global';

/**
 * Describes the schema for instructions which interact with the Guest Storage
 * system.
 */
export const GuestStorageInstructionSchema = Type.Union([
  GlobalPublishesTo({
    topic: 'GuestStorage:on-update',
    description: 'Published when a value is updated in the guest storage',
    meta: {},
    options: {title: 'Guest Storage: On Update'},
  }),
  GlobalSubscribesTo({
    topic: 'GuestStorage:tag:add',
    description: 'Command to add a value to the tags of a guest',
    meta: {
      value: Type.String({
        title: 'Value',
        description: 'Value to be added to tags',
      }),
    },
    options: {title: 'Guest Storage: Add Tag'},
  }),
  // `GuestStorage:list:add` is the generalized version of `tag:add`. In the
  // future `tag:add` can be converted to `list:add`
  GlobalSubscribesTo({
    topic: 'GuestStorage:list:add',
    description: 'Command to add a value to the list stored in a specific key',
    meta: {
      key: Type.String({
        title: 'Key',
        description: 'The key in guest storage to be modified',
      }),
      value: Type.String({
        title: 'Value',
        description: 'Value to be added to the list',
      }),
    },
    options: {title: 'Guest Storage: Add to List', '$lcd-flow-ignore': true},
  }),
  GlobalSubscribesTo({
    topic: 'GuestStorage:tag:remove',
    description: 'Command to remove a value from the list of tags for a guest',
    meta: {
      value: Type.String({
        title: 'Value',
        description: 'Value to be removed from the list',
      }),
    },
    options: {title: 'Guest Storage: Remove Tag'},
  }),
  // `GuestStorage:list:remove` is the generalized version of `tag:remove`. In
  // the future `tag:remove` can be converted to `list:remove`
  GlobalSubscribesTo({
    topic: 'GuestStorage:list:remove',
    description:
      'Command to remove a value from the list stored in a specific key',
    meta: {
      key: Type.String({
        title: 'Key',
        description: 'The key in guest storage to be modified',
      }),
      value: Type.String({
        title: 'Value',
        description: 'Value to be removed from the list',
      }),
    },
    options: {
      title: 'Guest Storage: Remove from List',
      '$lcd-flow-ignore': true,
    },
  }),
]);
